import { useState } from 'react'
import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(-1)

  function handleSubmenu(open: boolean, index: number) {
    if (open) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        const submenuOpen = currentIndex === index

        return (
          <li onMouseEnter={() => setCurrentIndex(index)} onMouseLeave={() => setCurrentIndex(-1)} className="relative mx-8" key={index}>
            <div className="flex flex-wrap items-center">
              <LosseLink className="text-xl font-bold text-site-base btn--pseudo lg:text-lg lg:font-light px-2" to={edge?.link?.url || '/'}>
                {edge?.link?.title}
              </LosseLink>
              {edge?.submenu && (
                <div onClick={() => handleSubmenu(submenuOpen, index)} className="ml-4" aria-label={`Submenu ${edge?.link?.title}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={
                      submenuOpen
                        ? {
                            transform: 'rotate(180deg)'
                          }
                        : {
                            transform: 'rotate(0deg)'
                          }
                    }
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1D3660"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="transition-all"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
              )}
            </div>
            {edge?.submenu && submenuOpen && (
              <AnimatePresence>
                <HeaderSubMenu fields={edge.submenu} />
              </AnimatePresence>
            )}
          </li>
        )
      })}
    </>
  )
}
