import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <nav data-component="Header" className={clsx(isSticky && 'shadow', 'fixed left-0 top-0 right-0 z-10 h-[60px] bg-white transition-all')}>
      <div className="container flex h-full items-center justify-between">
        <LosseLink to="/" aria-label="Home">
          <svg xmlns="http://www.w3.org/2000/svg" width="94" height="50" fill="none" viewBox="0 0 94 50" className="h-9 w-[70px]">
            <path
              fill="#070744"
              d="M20.615 45.765c0 .864 0 1.499-.037 1.984-.186 1.244-.861 1.648-2.088 1.797-.62.038-1.344.038-2.385.038h-5.779C3.06 49.584 0 46.629 0 39.216V26.53c.935 0 3.543-.112 4.398.454.564.41.712 1.244.712 2.842v9.204c0 4.415.967 6.219 5.216 6.219h5.222v-18.72c.966 0 3.5-.148 4.398.53.62.447.743 1.243.743 2.99v15.716h-.074zM32.935 49.559h-5.37c-3.649 0-4.287 0-4.287-3.632V30.212c0-3.856.861-3.67 4.287-3.67h5.259c6.485 0 9.093 2.588 9.093 6.288 0 2.059-.817 3.968-2.905 4.528a5.266 5.266 0 012.704 2.004 5.3 5.3 0 01.945 3.238c0 3.7-2.46 6.959-9.726 6.959zm4.027-16.238c0-1.461-.452-2.624-4.027-2.624h-4.553v5.168h4.807c3.717 0 3.767.112 3.767-2.544h.006zm-3.804 6.549h-4.776v5.597h4.733c4.336 0 4.435.075 4.435-2.88-.03-2.344-1.932-2.717-4.392-2.717zM56.047 49.82c-9.248 0-11.484-5.597-11.484-11.748 0-6.15 2.273-11.816 11.484-11.816 9.291 0 11.515 5.597 11.515 11.816 0 6.15-2.199 11.748-11.515 11.748zm6.405-11.71c0-4.46-1.115-7.569-6.405-7.569-6.374 0-6.337 0-6.337 7.531 0 4.453 1.152 7.519 6.337 7.519 6.448 0 6.374-.038 6.374-7.463l.03-.019z"
            />
            <path
              fill="#000CED"
              d="M3.878 17.693H0V0h17.623v3.893H3.878v13.8zM76.377 3.893V0H94v17.693h-3.878v-13.8H76.377zM90.122 32.127H94V49.82H76.377v-3.893h13.745v-13.8z"
            />
          </svg>
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
    </nav>
  )
}
