import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormContact" className="section">
      <div className="container">
        <Content className="content">{fields?.description}</Content>
        <div className="mt-5">
          <Form
            data={fields.form}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => (
              <div>
                <h2>{status}</h2> <p>{content}</p>
              </div>
            )}
          />
        </div>
      </div>
    </section>
  )
}
