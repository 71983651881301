import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import type { GlobalProductAttribute, ProductVariation, SimpleProduct, VariableProduct } from '~/graphql/types'
import 'dayjs/locale/nl'
import type { Fetcher } from '@ubo/losse-sjedel'
dayjs.locale('nl')

export function validateRedirect(redirect: string | null | undefined) {
  if (redirect?.startsWith('/') && redirect[1] !== '/') {
    return redirect
  }
  return null
}

export function currency(regularPrice: string, salePrice?: string) {
  if (regularPrice === '€&nbsp;0,00') {
    return `<span class="text-aj-vibrava">Gratis</span>`
  }

  if (!regularPrice.includes(',')) {
    regularPrice = regularPrice + ',-'
  }

  if (!regularPrice.includes('€')) {
    regularPrice = '€ ' + regularPrice
  }

  if (salePrice) {
    regularPrice = `<span class="line-through">${regularPrice}</span> <span class="text-aj-vibrava">${salePrice}</span>`
  }

  return regularPrice
}

export interface VariationOption {
  group: string
  attributeId: number
  value: string
  disabled?: boolean
}

export function useVariations(_product: SimpleProduct | VariableProduct): {
  variation: ProductVariation | undefined
  options: Record<string, VariationOption>
  setOptions: (combination: Record<string, VariationOption>) => void
  fields: Record<
    string,
    {
      label: string
      options: VariationOption[]
    }
  >
} {
  let selections:
    | Record<
        string,
        {
          label: string
          options: VariationOption[]
        }
      >
    | undefined = undefined

  if (_product.type !== 'VARIABLE') {
    const product = _product as SimpleProduct
    const attributes = product.attributes?.nodes.filter((attribute) => attribute.visible)

    if (!attributes) {
      throw new Error('No attributes found')
    }

    selections = attributes.reduce(
      (acc, _attribute) => {
        const name = _attribute.name as string
        if (!name) {
          return acc
        }
        if (_attribute.scope !== 'GLOBAL') {
          return acc
        }
        const attribute = _attribute as GlobalProductAttribute
        if (!attribute.terms?.nodes) {
          return acc
        }

        const options = [
          {
            group: name,
            attributeId: 0,
            value: attribute.name as string,
            disabled: false
          },
          ...attribute.terms?.nodes?.map((term) => ({
            group: name,
            attributeId: term.databaseId as number,
            value: term.name as string,
            disabled: false
          }))
        ]

        if (!options) {
          return acc
        }

        acc[name] = {
          label: attribute.label?.replace('-', ' ') as string,
          options: options
        }

        return acc
      },
      {} as Record<
        string,
        {
          label: string
          options: VariationOption[]
        }
      >
    )
  } else {
    const product = _product as VariableProduct

    selections = product.variations?.nodes.reduce(
      (acc, variation) => {
        for (const attribute of variation.attributes?.nodes || []) {
          const name = attribute.name as string
          if (!name) {
            continue
          }

          if (!acc[name]) {
            acc[name] = {
              label: attribute.label?.replace('-', ' ') as string,
              options: [
                {
                  group: name,
                  attributeId: 0,
                  value: attribute.label?.replace('-', ' ') as string,
                  disabled: false
                }
              ]
            }
          }

          if (acc[name].options.find((item) => item.attributeId === attribute.attributeId)) {
            continue
          }

          acc[name].options.push({
            group: name,
            attributeId: attribute.attributeId as number,
            value: attribute.value as string,
            disabled: false
          })
        }

        return acc
      },
      {} as Record<
        string,
        {
          label: string
          options: VariationOption[]
        }
      >
    )
  }

  const [combination, setCombination] = useState<Record<string, VariationOption>>(
    Object.fromEntries(Object.entries(selections || {}).map(([name, { options }]) => [name, options[0]]))
  )

  if (!selections) {
    throw new Error("Can't use variations on a product without variations")
  }

  let variation: ProductVariation | undefined = undefined
  if (_product.type === 'VARIABLE') {
    const product = _product as VariableProduct
    variation = product.variations?.nodes.find((variation) => {
      // do all attributes match?
      return variation.attributes?.nodes.every((attribute) => {
        const picked = combination[attribute.name as string]

        if (!picked) {
          return false
        }

        if (picked.attributeId === attribute.attributeId) {
          return true
        }

        return false
      })
    })
  } else {
    // if any combination is still default value, we don't have a variation
    if (Object.values(combination).some((option) => option.attributeId === 0)) {
      variation = undefined
    } else {
      // @ts-expect-error - we know this is a variable product
      variation = _product as VariableProduct
    }
  }

  return {
    variation,
    options: combination,
    setOptions: setCombination,
    fields: selections
  }
}

export function date(date: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs(date)
}

export function useCartMessage(cartFetcher: Fetcher) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!isVisible) return

    const timeout = setTimeout(() => {
      setIsVisible(false)
    }, 3500)

    return () => clearTimeout(timeout)
  }, [isVisible])

  useEffect(() => {
    if (cartFetcher.data && cartFetcher.state === 'idle') setIsVisible(true)
  }, [cartFetcher])

  return [isVisible]
}
