/* eslint-disable react-hooks/exhaustive-deps */
import type { FetcherWithComponents, LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, Link, useFetcher, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useRef } from 'react'
import { Tooltip } from 'react-tooltip'
import type { CartItem } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency } from '~/services/utils'
import Loading from './Loading'
import Quantity from './Quantity'

export default function Cart({ onClose }: { onClose: () => void }) {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const cartFetcher = useFetcher()
  const formRef = useRef<HTMLFormElement>(null)

  function handleClickOutside(event: MouseEvent) {
    if (formRef.current && !formRef.current.contains(event.target as Node)) onClose()
  }

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [formRef])

  const isEmpty = cart?.contents?.nodes?.length === 0

  return (
    <cartFetcher.Form className="bg-blue-50 rounded-2xl p-6" action="/api/cart" method="POST" id="cart" ref={formRef}>
      <input type="hidden" name="_action" value="update" />

      <div className="relative">
        <div className="flex justify-center relative items-center">
          <span className="text-lg lg:text-xl text-center font-bold">Winkelwand</span>

          <button
            type="button"
            onClick={onClose}
            className="btn--pseudo btn--base hover:bg-blue-500 flex items-center justify-center right-4 w-8 h-8 top-0 bottom-0 my-auto absolute"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {cart.contents.nodes.map((item) => {
          return <CartItemComponent key={item.key} item={item as unknown as CartItem} fetcher={cartFetcher} formRef={formRef} />
        })}

        {isEmpty && (
          <div className="p-10">
            <div className="text-center content py-6 mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="text-blue-500 mx-auto"
              >
                <g fill="currentColor" clipPath="url(#clip0_1329_2587)">
                  <path d="M24 3H4.242L4.2 2.649A3 3 0 001.222 0H0v2h1.222a1 1 0 01.993.883L3.8 16.351A3 3 0 006.778 19H20v-2H6.778a1 1 0 01-.993-.884L5.654 15h16.182L24 3zm-3.836 10H5.419l-.941-8h17.129l-1.443 8zM7 24a2 2 0 100-4 2 2 0 000 4zM17 24a2 2 0 100-4 2 2 0 000 4z"></path>
                </g>
              </svg>
              <span className="mt-2 block">Nog geen producten in de winkelmand</span>
            </div>
          </div>
        )}

        {!isEmpty && (
          <>
            <div className="flex justify-between text-lg py-5 px-8 font-bold">
              <span>Totaal</span>
              <span dangerouslySetInnerHTML={{ __html: currency(cart.total || '0') }} />
            </div>

            <div className="flex flex-col px-4 mb-2">
              <Link className="btn w-full text-center items-center justify-center text-lg" to="/winkelmand/">
                {cartFetcher.state !== 'idle' && <Loading className="text-white mr-2" />}
                <span>Afrekenen</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </cartFetcher.Form>
  )
}

/**
 * Since simple products can also have variation through attributes, we need to
 * convert the cart-item extraData into the same format as the variation attributes.
 */
export function variationAttributes(item: CartItem): {
  key: string
  value: string
}[] {
  let attributes: { key: string; value: string }[] = []

  if (item.variation?.attributes) {
    attributes = item.variation.attributes.reduce((acc, attribute) => {
      if (!attribute?.label || !attribute?.value) return acc
      acc.push({
        key: attribute.label,
        value: attribute.value
      })
      return acc
    }, [] as { key: string; value: string }[])
  }

  if (item.extraData && item.extraData?.length > 0) {
    attributes = item.extraData.reduce((acc, attribute) => {
      if (!attribute?.key || !attribute?.value) return acc
      acc.push({
        key: attribute.key,
        value: attribute.value
      })
      return acc
    }, [] as { key: string; value: string }[])
  }

  return attributes
}

function CartItemComponent({
  item,
  fetcher,
  formRef
}: {
  item: CartItem
  fetcher: FetcherWithComponents<unknown>
  formRef: React.RefObject<HTMLFormElement>
}) {
  const attributes = variationAttributes(item)

  return (
    <div key={item.key} className="flex justify-start gap-x-4 items-center mt-4">
      {item.product?.node.image && (
        <div className="rounded-lg">
          <LossePlaatjie className="w-[75px] h-auto" src={item.product?.node.image} maxwidth={300} />
        </div>
      )}
      {!item.product?.node.image && <div className="max-w-[75px] min-w-[75px] rounded-lg">No Image</div>}

      <div className="flex flex-col">
        <LosseLink to={`/product/${item.product?.node.slug}/`} className="flex items-center hover:underline max-sm:text-sm">
          {item.product?.node.name}
        </LosseLink>
        <div className="flex items-center">
          <span className="font-bold text-blue-500" dangerouslySetInnerHTML={{ __html: currency(item.total || '') }} />

          {attributes && attributes.length > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5 ml-1"
              data-tooltip-id={`${item.key}-variations`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          )}
          <Tooltip className="!bg-blue-500 flex flex-col" id={`${item.key}-variations`}>
            {attributes.map((attribute) => (
              <span key={attribute?.key}>
                <span className="font-bold">{attribute?.key?.replace('-', ' ')}</span>: {attribute?.value}
              </span>
            ))}
          </Tooltip>
        </div>
      </div>

      <Quantity
        item={item}
        onChange={() => {
          fetcher.submit(formRef.current)
        }}
        className="ml-auto"
      />
    </div>
  )
}
