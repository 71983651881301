import { LosseLink, LossePlaatjie, useFetcher, useLoaderData, useSearchParams, type LoaderData, Link } from '@ubo/losse-sjedel'
import { forwardRef, useRef } from 'react'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import Quantity from '~/components/elements/Quantity'
import type { CartItem, Page_Flexcontent_Flex_Cart } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency } from '~/services/utils'
import { registerLocale } from 'react-datepicker'
import nl from 'date-fns/locale/nl'
import clsx from 'clsx'
import { variationAttributes } from '~/components/elements/Cart'
import { CartBreadcrumbs } from '~/components/elements/Breadcrumbs'
registerLocale('nl', nl)

export default function Cart({ fields }: { fields: Page_Flexcontent_Flex_Cart }) {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const cartFetcher = useFetcher()
  const formRef = useRef<HTMLFormElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const state = searchParams.get('state')

  function onChangeQuantity() {
    cartFetcher.submit(formRef.current, {
      replace: false
    })
  }

  return (
    <section data-component="Cart" className="container section">
      <CartBreadcrumbs type="cart" />

      <cartFetcher.Form
        className="max-w-screen-xl xl:px-8 mx-auto grid grid-cols-2 md:grid-cols-3 cart--grid--gap items-start"
        action="/api/cart"
        method="POST"
        id="cart"
        replace={true}
        ref={formRef}
      >
        <div className="col-span-2 flex flex-col cart--grid--gap">
          <input type="hidden" name="_action" value="update" />

          {(state === 'same_day_pickup' || state === 'later_pickup') && (
            <div
              className="w-full h-full fixed left-0 top-0 bg-black/50 z-[490]"
              onClick={() => {
                if (searchParams.has('state')) {
                  searchParams.delete('state')
                }
                setSearchParams(searchParams, {
                  preventScrollReset: true,
                  state: {
                    scroll: false
                  }
                })
              }}
            />
          )}

          {cart.contents.itemCount === 0 && <div className="p-4 lg:p-6 flex flex-col relative">No items in cart</div>}

          {cart.contents.nodes.map((_item, i) => {
            const item = _item as unknown as CartItem
            const attributes = variationAttributes(item)
            return (
              <div key={item.key} className={clsx('bg-white rounded-2xl p-4 lg:p-6 relative flex flex-col gap-y-6')}>
                <div
                  className="grid sm:flex grid-cols-4 gap-3 sm:gap-4 lg:gap-8 max-sm:border-b max-sm:border-rc-litten max-sm:last:border-b-transparent max-sm:pb-3"
                  key={item.key}
                >
                  <div className="max-sm:w-full max-sm:col-span-1 max-w-full min-w-full sm:max-w-[90px] sm:min-w-[90px] lg:min-w-[125px] lg:max-w-[125px] sm:h-auto h-[74px]">
                    {item.product?.node.image && (
                      <div className="w-full rounded-xl flex-none">
                        <LossePlaatjie src={item.product?.node.image} maxwidth={300} />
                      </div>
                    )}
                    {!item.product?.node.image && <div className="w-full rounded-xl flex-none">No image</div>}
                  </div>

                  <div className="flex flex-col max-sm:col-span-3 sm:pt-1 w-full" aria-label="Product informatie">
                    <LosseLink
                      to={`/product/${item.product?.node.slug}/`}
                      className="max-sm:text-sm lg:text-lg font-bold mb-1 lg:mb-2 hover:underline"
                    >
                      {item.product?.node.name}
                    </LosseLink>
                    {attributes?.map((attribute) => (
                      <div className="text-rc-quilladin opacity-50 text-s sm:text-sm" key={attribute?.key}>
                        <span className="font-bold">{attribute?.key?.replace('-', ' ')}</span>
                        <span>: {attribute?.value}</span>
                      </div>
                    ))}
                  </div>

                  <div className="sm:hidden col-span-1" />

                  <div className="sm:-mt-1 max-sm:col-span-2 max-sm:flex justify-start">
                    <Quantity item={item} onChange={onChangeQuantity} className="max-sm:bg-rc-litten" />
                  </div>

                  <div className="flex sm:flex-col items-center sm:items-end max-sm:col-span-1">
                    <Content className="text-base sm:text-lg lg:text-xl text-rc-sandaconda font-bold w-[80px] lg:w-[100px] text-right">
                      {currency(item.total || '0')}
                    </Content>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="bg-rc-shedinja max-md:col-span-2 rounded-2xl p-4 lg:py-6 lg:px-6 xl:px-10">
          <p className="text-white text-center text-s lg:text-sm mb-7 lg:-mx-2 font-rc-primary font-bold">
            Op dit moment bezorgen wij nog niet. Bezorgen van zakelijke bestellingen in overleg.
          </p>

          <div className="grid grid-cols-2 gap-y-3 lg:gap-y-4">
            <div>Subtotaal</div>
            <Content className="text-right">{currency(cart.subtotal)}</Content>
            <div>BTW</div>
            <Content className="text-right">{currency(cart.totalTax)}</Content>

            <div className="cart--total mt-3 lg:mt-4">Totaal</div>
            <Content className="text-right cart--total mt-3 lg:mt-4">{currency(cart.total)}</Content>
          </div>

          <div className="flex flex-col mb-2 mt-4">
            <Link to="/afrekenen/" className="btn w-full text-center items-center justify-center lg:text-lg lg:py-3" type="button">
              {cartFetcher.state !== 'idle' && <Loading className="text-white mr-2" />}
              <span>Verder met bestellen</span>
            </Link>
          </div>
        </div>
      </cartFetcher.Form>
    </section>
  )
}

const DatepickerInput = forwardRef<HTMLInputElement>(({ ...rest }, ref) => (
  <input {...rest} className="form-duuf-input md:h-[42px] max-lg:text-sm first-letter:capitalize" readOnly ref={ref} />
))
DatepickerInput.displayName = 'Input'
