import type { Fetcher } from '@ubo/losse-sjedel'
import { useFetcher } from '@ubo/losse-sjedel'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import type { SimpleProduct, VariableProduct } from '~/graphql/types'
import { useCartMessage, currency } from '~/services/utils'
import VariationModal from './VariationModal'
import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'

export default function GlobalAddToCartButton({
  product,
  showText = false,
  size = 'normal'
}: {
  product: SimpleProduct | VariableProduct
  showText?: boolean
  size?: 'normal' | 'big'
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const cartFetcher = useFetcher()
  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('!overflow-hidden')
    } else {
      document.body.classList.remove('!overflow-hidden')
    }
  }, [isModalOpen])

  const hasOptions = product.attributes?.nodes?.find((attribute) => attribute?.visible)

  return (
    <div className="flex items-center">
      {!(hasOptions || product.type === 'VARIABLE') ? (
        <cartFetcher.Form action="/api/cart" method="post">
          <input type="hidden" name="_action" value="add" />
          <input type="hidden" name="productId" value={product.databaseId} />

          <AddToCartButton
            showMessage={isCartMessageAvailable}
            cartFetcher={cartFetcher}
            text={showText ? 'Voeg toe' : currency(product.regularPrice || '0', product.salePrice || undefined)}
            size={size === 'big' ? 'big' : 'normal'}
          />
        </cartFetcher.Form>
      ) : (
        <button type="button" onClick={() => setIsModalOpen(true)} className="btn flex items-center mr-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="lg:w-5 lg:h-5 w-4 h-4 -ml-1 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
          <span dangerouslySetInnerHTML={{ __html: currency(product.price || '') }} />
        </button>
      )}

      <AnimatePresence>
        {isCartMessageAvailable && !cartFetcher.data.success && (
          <motion.div initial={{ opacity: 0, marginLeft: 5 }} animate={{ opacity: 1, marginLeft: 12 }} exit={{ opacity: 0, marginLeft: 5 }}>
            <span className="form-duuf-error">Kon product niet toevoegen aan winkelmand</span>
          </motion.div>
        )}
      </AnimatePresence>

      {isModalOpen &&
        (hasOptions || product.type === 'VARIABLE') &&
        typeof document !== 'undefined' &&
        createPortal(
          <VariationModal
            onClose={() => {
              setIsModalOpen(false)
            }}
            product={product}
          />,
          document.body
        )}
    </div>
  )
}

export function AddToCartButton({
  className,
  showMessage,
  cartFetcher,
  text,
  size
}: {
  className?: string
  showMessage: boolean
  cartFetcher: Fetcher
  text: any
  size?: 'normal' | 'big'
}) {
  const initialized = useRef(false)
  const successfullyAdded = showMessage && cartFetcher.data.success

  useEffect(() => {
    initialized.current = true
  }, [])

  return (
    <button type="submit" className={clsx('btn flex items-center overflow-hidden', className)}>
      {successfullyAdded && (
        <div className="rounded-full border-2 border-white flex-center -ml-2 mr-2 lg:h-6 lg:w-6 w-4 h-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-white w-3 h-3 lg:w-[14px] lg:h-[14px]"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
      )}
      {!successfullyAdded && (
        <>
          {cartFetcher.state !== 'idle' ? (
            <svg
              className="animate-spin -ml-2 mr-2 lg:h-6 lg:w-6 w-4 h-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              className="-ml-2 mr-2 w-4 h-4 lg:w-6 lg:h-6"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10zm1-11h4v2h-4v4h-2v-4H7v-2h4V7h2v4z"
              ></path>
            </svg>
          )}
        </>
      )}
      <AnimatePresence mode="wait">
        {successfullyAdded && (
          <motion.span
            key="added"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            exit={{ y: 50 }}
            className={clsx(size === 'big' && 'lg:text-lg')}
          >
            Toegevoegd
          </motion.span>
        )}
        {!successfullyAdded && (
          <motion.span
            key="price"
            initial={{ y: initialized.current ? -50 : 0 }}
            animate={{ y: 0 }}
            exit={{ y: 50 }}
            dangerouslySetInnerHTML={{ __html: text }}
            className={clsx(size === 'big' && 'lg:text-lg')}
          />
        )}
      </AnimatePresence>
    </button>
  )
}
