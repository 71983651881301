/* eslint-disable react-hooks/exhaustive-deps */
import { useFetcher, LossePlaatjie } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
// import Allergens from '~/components/elements/product/Allergens'
import VariationSelect from '~/components/elements/product/VariationSelect'
import type { SimpleProduct, VariableProduct } from '~/graphql/types'

import { useCartMessage, currency, useVariations } from '~/services/utils'
import Content from '../Content'
import { AddToCartButton } from './GlobalAddToCartButton'

export default function VariationModal({ product, onClose }: { product: SimpleProduct | VariableProduct; onClose: () => void }) {
  const { variation, fields, setOptions, options } = useVariations(product)
  const cartFetcher = useFetcher()
  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <cartFetcher.Form
      method="post"
      action="/api/cart"
      className="fixed left-0 top-0 w-full h-full  flex items-center justify-center z-[1000]"
    >
      <input type="hidden" name="_action" value="add" />
      <input type="hidden" name="productId" value={product.databaseId} />
      {product.databaseId !== variation?.databaseId && <input type="hidden" name="variationId" value={variation?.databaseId} />}
      {product.databaseId === variation?.databaseId && (
        <input
          type="hidden"
          name="extraData"
          value={JSON.stringify(Object.values(options).reduce((acc, option) => ({ ...acc, [option.group]: option.value }), {}))}
        />
      )}

      <div onClick={() => onClose()} className="bg-black/50 w-full h-full absolute left-0 top-0" />
      <motion.div
        initial={{
          scale: 0.8,
          opacity: 0
        }}
        animate={{
          scale: 1,
          opacity: 1
        }}
        className="bg-white rounded-2xl relative z-10  max-w-xl w-full mx-4"
      >
        {product.image && (
          <LossePlaatjie
            type="fluid"
            src={product.image}
            className="rounded-2xl aspect-[16/10] object-cover relative w-full"
            autoheight={false}
            maxwidth={800}
          />
        )}
        {!product.image && <div className="rounded-2xl aspect-[16/10] object-cover relative w-full bg-blue-500">No image</div>}

        <button onClick={() => onClose()} className="absolute right-0 top-0 m-4">
          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34">
            <g clipPath="url(#clip0_1146_4389)">
              <path
                fill="#fff"
                d="M34 2.003L31.997 0 17 14.997 2.003 0 0 2.003 14.997 17 0 31.997 2.003 34 17 19.003 31.997 34 34 31.997 19.003 17 34 2.003z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_1146_4389">
                <path fill="#fff" d="M0 0H34V34H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </button>

        <div className="p-4 lg:p-6">
          <h2 className="text-lg sm:text-xl lg:text-2xl mb-1 font-bold">{product.name}</h2>
          <Content className="content mb-4">{product.description}</Content>

          <div className="flex gap-x-4 flex-wrap gap-y-2 lg:gap-y-4">
            {Object.entries(fields).map(([name, value]) => (
              <VariationSelect
                key={name}
                selected={options[name]}
                setSelected={(option) => {
                  setOptions({
                    ...options,
                    [name]: option
                  })
                }}
                options={value.options}
              />
            ))}
          </div>

          <div className="grid items-end mt-4">
            {/* <Allergens product={product} /> */}
            <div className="flex items-center">
              <AnimatePresence>
                {isCartMessageAvailable && !cartFetcher.data.success && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-right pr-4 form-duuf-error"
                  >
                    Kon product niet toevoegen aan winkelmand
                  </motion.div>
                )}
              </AnimatePresence>

              {variation ? (
                <AddToCartButton
                  className="ml-auto"
                  cartFetcher={cartFetcher}
                  showMessage={isCartMessageAvailable}
                  text={currency(variation.regularPrice || '', variation.salePrice || undefined)}
                />
              ) : (
                <div className="flex justify-end">
                  <button type="button" className="ml-auto text-sm text-left">
                    {Object.values(options).some((item) => item.attributeId === 0)
                      ? 'Kies tussen de opties om door te gaan'
                      : 'Deze combinatie is niet beschikbaar'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </cartFetcher.Form>
  )
}
