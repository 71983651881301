import { useEffect } from 'react'
import HeaderMenu from './HeaderMenu'
import { useLayout } from '../Layout'
import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData, useNavigation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import type { CommerceLoaderData } from '~/services/session.server'
import Cart from '~/components/elements/Cart'

export default function HeaderDesktop() {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const layout = useLayout()
  const navigation = useNavigation()
  const itemCountControls = useAnimation()
  useEffect(() => {
    if (navigation.state !== 'idle') {
      return
    }

    layout.setIsCartOpen(false)
  }, [navigation])

  return (
    <ul className="hidden w-full lg:items-center lg:justify-end lg:flex">
      <HeaderMenu />

      <button
        type="button"
        onClick={() => {
          layout.setIsCartOpen(!layout.isCartOpen)
        }}
        className={clsx(layout.isCartOpen && 'pointer-events-none', 'relative btn--base btn--pseudo p-2 hover:bg-blue-500 group')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          className={clsx(
            layout.typeHeader === 'default' && 'text-blue-500 group-hover:text-white',
            layout.typeHeader === 'transparent' && 'text-white'
          )}
        >
          <g fill="currentColor" clipPath="url(#clip0_1329_2587)">
            <path d="M24 3H4.242L4.2 2.649A3 3 0 001.222 0H0v2h1.222a1 1 0 01.993.883L3.8 16.351A3 3 0 006.778 19H20v-2H6.778a1 1 0 01-.993-.884L5.654 15h16.182L24 3zm-3.836 10H5.419l-.941-8h17.129l-1.443 8zM7 24a2 2 0 100-4 2 2 0 000 4zM17 24a2 2 0 100-4 2 2 0 000 4z"></path>
          </g>
        </svg>
        <motion.span
          className="p-1 px-[5.5px] bg-blue-500 rounded-full flex items-center justify-center text-white absolute -right-[2px] -top-[2px] text-[10px] leading-none"
          animate={itemCountControls}
        >
          {cart.contents.itemCount}
        </motion.span>
      </button>

      <AnimatePresence>
        {layout.isCartOpen && (
          <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1.0 }} className="fixed right-10 top-10">
            <div className="max-md:rounded-2xl max-md:max-h-full max-md:shadow--box max-md:bg-blue-500 max-md:overflow-auto max-md:max-w-lg">
              <Cart onClose={() => layout.setIsCartOpen(false)} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ul>
  )
}
