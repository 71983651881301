import { useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer data-component="Footer">
      <div className="container py-5">
        <h2 className="pb-3 text-2xl font-bold">Footer</h2>
        <nav>
          <ul>
            {footer?.menuFooter?.map((edge, index) => (
              <li className="py-1" key={index}>
                <LosseLink className="hover:underline" to={edge?.link?.url || '/'}>
                  {edge?.link?.title}
                </LosseLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  )
}
