import { json, useLoaderData, useNavigation, wordpressLoader } from '@ubo/losse-sjedel'
import type { LoaderData, LoaderFunction } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import { getCartClient } from '~/services/session.server'

export const customWordpressLoader: LoaderFunction = async (data) => {
  const client = await getCartClient(data.request)
  const [page, res] = await Promise.all([wordpressLoader(data), client.getCartAndCustomer()])

  return {
    body: {
      ...page.body,
      cart: res.cart,
      customer: res.customer
    },
    headers: page.headers
  }
}

export const loader: LoaderFunction = async (data) => {
  const page = await customWordpressLoader(data)

  if (page.body.page.flexContent.flex.length === 0 && data.request.url.includes('/product/')) {
    page.body.page.flexContent.flex.push({
      styletype: 'two_columns',
      fieldGroupName: 'Product_Flexcontent_Flex_ProductDetail',
      description: page.body.page.excerpt
    })
  }

  return json(page.body, page.headers)
}

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  const transition = useNavigation()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      progress.start()
    } else {
      progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex as any} />
    </Layout>
  )
}
