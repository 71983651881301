import { createContext, useContext, type PropsWithChildren, useState, useEffect } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLayout, useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import type { CommerceLoaderData } from '~/services/session.server'
import clsx from 'clsx'

type TypeHeader = 'default' | 'transparent'

interface LayoutContextProps {
  typeHeader?: TypeHeader
  isCartOpen: boolean
  setIsCartOpen: (open: boolean) => void
}

export const LayoutContext = createContext<LayoutContextProps | null>(null)

export const useLayout = () => {
  const context = useContext(LayoutContext)

  return context as LayoutContextProps
}

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content children={children} />
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const data = useLoaderData<LoaderData & CommerceLoaderData & { typeHeader: TypeHeader }>()
  const { isScrollable, isSticky } = useLosseLayout()
  const [isCartOpen, setIsCartOpen] = useState(false)

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflow = 'hidden'
    } else if (!isCartOpen) {
      document.body.style.overflow = 'auto'
    }
  }, [isCartOpen])

  return (
    <LayoutContext.Provider value={{ typeHeader: isSticky ? 'default' : data.typeHeader || 'default', isCartOpen, setIsCartOpen }}>
      <div className={clsx(isScrollable ? `h-full` : `h-screen overflow-hidden`)}>
        <Header />
        <main className="min-h-screen-dynamic pt-[60px]">{children}</main>
        <Footer />
      </div>
    </LayoutContext.Provider>
  )
}
