import clsx from 'clsx'

interface TextAreaProps {
  name: string
  label: string
  required?: boolean
  className?: string
}
export default function Textarea(props: TextAreaProps) {
  return (
    <label className={clsx('flex flex-col', props.className)}>
      <textarea
        className="w-full form-duuf-textarea text-black !rounded-md px-4 py-2 h-full"
        placeholder={props.label}
        required={props.required}
        name={props.name}
      ></textarea>
    </label>
  )
}
