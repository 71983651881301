import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import GlobalAddToCartButton from '~/components/elements/product/GlobalAddToCartButton'
import type { Product } from '~/graphql/types'

export default function Product({ data }: { data: Product }) {
  const product = data.woo.node

  return (
    <div>
      <LosseLink to={data.uri} className="aspect-video w-full rounded-xl overflow-hidden relative">
        <LossePlaatjie
          src={data.woo.node.image}
          className="w-full h-full object-cover"
          placeholder="blur"
          maxWidth={600}
          title={data.title}
        />
      </LosseLink>
      <h2 className="swiper-no-swiping text-2xl line-clamp-1 mt-2">{data.title}</h2>
      <GlobalAddToCartButton product={product} />
      {/* <Content className="line-clamp-1">{data.recap?.}</Content> */}
    </div>
  )
}
