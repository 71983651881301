import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsHighlighted from './PostsHighlighted'
import PostsBlog from './PostsBlog'
import type { ContentNode, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Post from './post-types/Post'
import Product from './post-types/Product'

export type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

export const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

export const PostTypes: { [key: string]: any } = {
  Post: Post,
  Product: Product
}

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    highlighted: PostsHighlighted,
    blog: PostsBlog
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
