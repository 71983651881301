/* eslint-disable react-hooks/exhaustive-deps */
import type { LoaderData } from '@ubo/losse-sjedel'
import { useNavigation, losseLoader, useFetchers, useLoaderData, Form, useFetcher } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment, useEffect, useRef, useState } from 'react'
import { CartBreadcrumbs } from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import LoadingOverlay from '~/components/elements/LoadingOverlay'
import Checkbox from '~/components/elements/form-elements/Checkbox'
import Input from '~/components/elements/form-elements/Input'
import SubmitButton from '~/components/elements/form-elements/SubmitButton'
import Textarea from '~/components/elements/form-elements/Textarea'
import type { PaymentGateway, RootQueryToPaymentGatewayConnection } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency } from '~/services/utils'

export default function Checkout() {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const [touched, setTouched] = useState<Record<string, boolean>>({})
  const ref = useRef<HTMLFormElement>(null)
  const cartFetcher = useFetcher()
  const couponFetcher = useFetcher<{ error: boolean; message: string }>()
  const gatewaysFetcher = useFetcher<{ paymentGateways: RootQueryToPaymentGatewayConnection }>()
  const fetchers = useFetchers()
  const navigation = useNavigation()
  const [useShippingAddress, setUseShippingAddress] = useState<boolean>(false)

  const isAnyFetcherBusy = Object.values(fetchers).some((fetcher) => fetcher.state !== 'idle')

  function updateDeliveryState() {
    if (!ref.current) return
    const formData = new FormData(ref.current)
    formData.append('_action', 'update_customer')

    cartFetcher.submit(formData, {
      action: '/api/cart',
      method: 'post'
    })
  }

  useEffect(() => {
    const query = losseLoader({
      name: 'PaymentGateways'
    })

    gatewaysFetcher.load(query)
  }, [])

  useEffect(() => {
    if (!ref.current) return

    ref.current.addEventListener('change', (event) => {
      if (!event.target) return

      const target = event.target as HTMLFormElement

      touched[target.name] = true
      setTouched({ ...touched })

      switch (target.name) {
        case 'shipping_postcode':
        case 'billing_postcode':
          updateDeliveryState()

        default:
          break
      }
    })
  }, [])

  const hasItems = cart.contents.itemCount > 0

  return (
    <section data-component="Checkout" className="container section">
      <CartBreadcrumbs type="checkout" />

      <Form
        className="max-w-screen-xl xl:px-8 mx-auto grid grid-cols-1 sm:grid-cols-3 cart--grid--gap items-start"
        action="/api/checkout"
        method="POST"
        id="checkout"
        ref={ref}
      >
        <div className={clsx(hasItems && 'bg-white', 'col-span-1 sm:col-span-2 rounded-2xl p-4 md:p-6 relative flex flex-col gap-8')}>
          {!hasItems && 'Geen producten in je winkelmand'}

          {hasItems && (
            <>
              <h2 className="font-bold text-lg col-span-4">Je gegevens</h2>
              <div className="max-w-xl grid grid-cols-1 sm:grid-cols-4 gap-4">
                <Input type="text" className="sm:col-span-2" label="Voornaam" name="billing_first_name" required />
                <Input type="text" className="sm:col-span-2" label="Achternaam" name="billing_last_name" required />
                <Input type="text" className="col-span-4" label="Bedrijfsnaam" name="billing_company" />
                <Input type="text" className="sm:col-span-3" label="Straatnaam" name="billing_address_1" required />
                <Input type="text" className="sm:col-span-1" label="Huis nr." name="billing_address_2" required />
                <Input type="text" className="sm:col-span-2" label="Woonplaats" name="billing_city" required />
                <input type="hidden" name="billing_country" value="NL" />
                <div className="sm:col-span-2 flex flex-col">
                  <Input type="text" className="w-full" label="Postcode" name="billing_postcode" required />
                  {!useShippingAddress &&
                    touched['billing_postcode'] &&
                    cartFetcher.state === 'idle' &&
                    cart.availableShippingMethods?.find((method) => method?.rates) === undefined && (
                      <div className="text-red-500">Deze postcode is buiten onze transportzone.</div>
                    )}
                </div>
                <Input type="text" className="sm:col-span-2" label="Telefoonnummer" name="billing_phone" required />
                <Input type="email" className="sm:col-span-2" label="E-mailadres" name="billing_email" required />
                <Textarea name="customer_note" label="Opmerkingen" className="sm:col-span-4 h-[100px]" />
              </div>

              <div className="max-w-xl">
                <div className="mb-4">
                  <Checkbox
                    onChange={(e) => setUseShippingAddress(e.target.checked)}
                    label="Afleveren op ander adres?"
                    name="use_shipping_address"
                  />
                </div>

                {useShippingAddress && (
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <Input type="text" className="col-span-2" label="Voornaam" name="shipping_first_name" required />
                    <Input type="text" className="col-span-2" label="Achternaam" name="shipping_last_name" required />
                    <Input type="text" className="col-span-4" label="Bedrijfsnaam" name="shipping_company" />
                    <Input type="text" className="col-span-3" label="Straatnaam" name="shipping_address_1" required />
                    <Input type="text" className="col-span-1" label="Huis nr." name="shipping_address_2" required />
                    <Input type="text" className="col-span-2" label="Woonplaats" name="shipping_city" required />
                    <input type="hidden" name="shipping_country" value="NL" />

                    <div className="col-span-2 flex flex-col">
                      <Input type="text" className="w-full" label="Postcode" name="shipping_postcode" required />
                      {useShippingAddress &&
                        touched['shipping_postcode'] &&
                        cartFetcher.state === 'idle' &&
                        cart.availableShippingMethods?.find((method) => method?.rates) === undefined && (
                          <div className="text-red-500">Deze postcode is buiten onze transportzone.</div>
                        )}
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-4 gap-x-4 max-w-lg">
                <h2 className="font-bold col-span-4 mb-2 text-lg">Kortingscode</h2>
                <Input type="text" className="col-span-2" label="Kortingscode" name="coupon_code" />
                <div className="col-span-2 flex items-center">
                  <SubmitButton
                    onClick={() => {
                      if (!ref.current) return
                      const formData = new FormData(ref.current)
                      formData.append('_action', 'apply_coupon')
                      couponFetcher.submit(formData, {
                        action: '/api/cart',
                        method: 'post'
                      })
                    }}
                    className="btn"
                    type="button"
                    loading={couponFetcher.state === 'submitting'}
                  >
                    Pas <span className="max-sm:hidden">kortingscode </span>toe
                  </SubmitButton>
                </div>

                {couponFetcher.data && couponFetcher.state !== 'submitting' && (
                  <div
                    dangerouslySetInnerHTML={{ __html: couponFetcher.data.message }}
                    className={clsx('col-span-4 mt-1', couponFetcher.data.error ? 'text-red-500' : 'text-blue-500')}
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div className="bg-rc-shedinja text-black rounded-2xl p-4 lg:py-6 lg:px-6 xl:px-10 relative flex overflow-hidden flex-col gap-4">
          {isAnyFetcherBusy && <LoadingOverlay />}
          <h2 className="text-black font-rc-plus font-bold text-lg sm:text-xl xl:text-2xl">Afhalen</h2>

          <div className="grid grid-cols-4 gap-y-4">
            <div className="col-span-2 lg:col-span-3">Subtotaal</div>
            <Content className="text-right col-span-2 lg:col-span-1">{currency(cart.subtotal)}</Content>
            <div className="col-span-2 lg:col-span-3">BTW</div>
            <Content className="text-right col-span-2 lg:col-span-1">{currency(cart.totalTax)}</Content>
            {cart.appliedCoupons?.map((coupon) => (
              <Fragment key={coupon?.code}>
                <div className="flex flex-col col-span-3">
                  <span>Kortingscode:</span>

                  <div className="flex items-center gap-x-1">
                    <b>{coupon?.code}</b>
                    <button
                      onClick={() => {
                        if (!ref.current) return
                        if (!coupon?.code) return
                        const formData = new FormData()
                        formData.append('_action', 'remove_coupon')
                        formData.append('coupon_code', coupon?.code)
                        cartFetcher.submit(formData, {
                          action: '/api/cart',
                          method: 'post'
                        })
                      }}
                      title="Kortingscode verwijderen"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <Content className="text-right">{`-${coupon?.discountAmount}`}</Content>
              </Fragment>
            ))}

            <div className="cart--total col-span-2 lg:col-span-3">Totaal</div>
            <Content className="cart--total text-right col-span-2 lg:col-span-1">{currency(cart.total)}</Content>

            <strong className="mb-1 mt-4 block col-span-4 lg:col-span-2">Betaalmethodes</strong>
            <div className="flex flex-col col-span-4 lg:col-span-2 gap-y-2 mb-1 lg:pl-3 lg:mt-4 -mt-4">
              {gatewaysFetcher.data?.paymentGateways?.nodes?.map((node) => {
                const gateway = node as PaymentGateway
                return (
                  <label className="flex items-center" key={gateway?.id}>
                    <input
                      type="radio"
                      required
                      defaultChecked={gateway.id === 'mollie_wc_gateway_ideal'}
                      name="payment_method"
                      value={gateway?.id}
                      id={gateway?.id}
                    />
                    <span className="ml-2 mr-4">{gateway?.title}</span>
                    <Content>{gateway?.icon}</Content>
                  </label>
                )
              })}
            </div>
          </div>

          <Checkbox
            label={`Ik heb de <a target="_blank" class="text-rc underline" href="/privacyverklaring/">privacyverklaring</a> gelezen en ga hiermee akkoord`}
            name="terms"
            required
          />

          <SubmitButton
            type="submit"
            loading={navigation.state === 'submitting'}
            className="btn flex justify-center items-center lg:py-3 lg:text-lg"
            disabled={!hasItems}
          >
            Bestelling bevestigen
          </SubmitButton>
        </div>
      </Form>
    </section>
  )
}
