import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Slider from '~/components/elements/Slider'
import { Autoplay } from 'swiper/modules'
import { PostTypes, getPostTypeName } from './PostsShell'
import { SwiperSlide } from 'swiper/react'

export default function PostsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="Highlighted" className="section">
      <div className="container">
        <Slider
          modules={[Autoplay]}
          autoplay
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
          className="rounded-xl overflow-hidden"
        >
          {fields.posts?.edges?.map((edge) => {
            const postTypeName = getPostTypeName(fields)

            if (!edge || !edge.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Post

            return (
              <SwiperSlide key={edge.node.uri}>
                <Component data={edge.node} />
              </SwiperSlide>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
