import { Link, LosseKruumels } from '@ubo/losse-sjedel'

export default function Breadcrumbs() {
  return (
    <LosseKruumels
      type="fragment"
      className="children-ol:flex children-li:flex children-ol:items-center text-site-base hover:children-a:underline children-a:first-letter:uppercase children-a:inline-block"
      seperator={
        <span className="mx-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </span>
      }
    />
  )
}

export function CartBreadcrumbs({ type = 'cart' }: { type: 'cart' | 'checkout' }) {
  return (
    <div className="flex items-start mb-8 max-sm:text-sm flex-wrap">
      <button
        onClick={() => {
          window.history.back()
        }}
        className="flex items-center gap-x-4 mr-4 hover:underline"
        type="button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" fill="none" viewBox="0 0 7 11">
          <path fill="#000000" d="M5.442.19l1.117 1.12L2.37 5.5 6.56 9.69l-1.12 1.12-4.185-4.19a1.583 1.583 0 010-2.24L5.442.19z"></path>
        </svg>
        <span>Verder winkelen</span>
      </button>

      {type === 'cart' && (
        <div className="flex items-center gap-x-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" fill="none" viewBox="0 0 7 11">
            <path
              fill="#000000"
              d="M1.559 10.81L.442 9.69 4.629 5.5.442 1.31 1.562.19l4.184 4.19a1.583 1.583 0 010 2.24l-4.187 4.19z"
            ></path>
          </svg>

          <strong className="block">Winkelmand</strong>
        </div>
      )}

      {type === 'checkout' && (
        <>
          <Link to="/winkelmand/" className="flex items-center gap-x-4 mr-4 hover:underline">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" fill="none" viewBox="0 0 7 11">
              <path
                fill="#000000"
                d="M1.559 10.81L.442 9.69 4.629 5.5.442 1.31 1.562.19l4.184 4.19a1.583 1.583 0 010 2.24l-4.187 4.19z"
              ></path>
            </svg>

            <span className="block">Winkelmand</span>
          </Link>

          <div className="flex items-center gap-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" fill="none" viewBox="0 0 7 11">
              <path
                fill="#000000"
                d="M1.559 10.81L.442 9.69 4.629 5.5.442 1.31 1.562.19l4.184 4.19a1.583 1.583 0 010 2.24l-4.187 4.19z"
              ></path>
            </svg>

            <strong className=" block">Afrekenen</strong>
          </div>
        </>
      )}
    </div>
  )
}
