interface CheckboxProps {
  name: string
  label: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
}
export default function Checkbox(props: CheckboxProps) {
  return (
    <label className="flex items-start cursor-pointer mb-2 max-lg:mt-4">
      <input onChange={props.onChange} type="checkbox" name={props.name} className="hidden peer sr-only" required={props.required} />
      <div className="-mt-[2px] flex-none w-4 h-4 border border-solid border-black bg-white mr-3 peer-checked:hidden"></div>
      <div className="-mt-[2px] peer-checked:flex hidden bg-blue-500 mr-3 w-4 h-4 border border-solid border-black items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      <span className="select-none -mt-1 text-sm" dangerouslySetInnerHTML={{ __html: props.label }} />
    </label>
  )
}
